.footer {
    position: absolute;
    bottom: 0;
    background-color: #262525;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.footer-social {
    height: 20px;
    width: 20px;
    margin-right: 24px;
}

.footer svg {
    height: 100%;
    width: 100%;
    fill: #6C6C6C;
    cursor: pointer;
}

.footer-text {
    font-size: 12px;
    margin-right: 24px;
    color: #6C6C6C;
    padding-left: 80px;
}

@media only screen and (max-width: 599px) {
    .footer-text {
        padding-left: 40px;
    }
}
select {
    border: 0;
    border-bottom: 1px solid #000000;
    padding-left: 6px;
    padding-bottom: 4px;
    outline: none;
    width: 85px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background: url(arrow_down.png) no-repeat right;
    font-family: "Poppins", "SansSerif", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #242424
}
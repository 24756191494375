.form-container-backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.55);
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.form-layout-container {
    position: absolute;
    z-index: 10;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 600ms;
}

.form-center {
    margin: auto 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    overflow: auto;
    transition: margin 0.3s;
}


@media only screen and (min-width: 1024px) {
    .width-100 {
        margin-right: 0;
    }

    .width-0 {
        margin-right: -40%;
    }
    .form-container {
        width: 100%;
    }
}

.hidden {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: visibility 0s linear 600ms, opacity 600ms !important;
}

.form-container {
    background-color: white;
    color: #242424;
    padding: 40px 60px 0 40px;
    font-size: 14px;
    overflow: auto;
}

@media only screen and (max-width: 599px) {
    .form-container {
        padding: 40px 16px;
        font-size: 12px;
    }
    .form-center {
        margin: auto;
        flex-direction: column;
        align-items: flex-end;
        max-width: 90%;
        max-height: 90%;
    }
}

@media only screen and (max-width: 1023px) and (min-width:600px) {
    .form-center {
        margin: auto;
        flex-direction: column;
        align-items: flex-end;
        max-width: 80%;
        max-height: 80%;
    }
}

.close-form-icon {
    float: right;
    width: 24px;
    margin-right: 16px;
    cursor: pointer;
}

.form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
}

@media only screen and (max-width: 599px) {
    .close-form-icon {
        margin-bottom: 16px;
        margin-right: 0;
    }
    .form-title {
        font-size: 18px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 1023px) and (min-width:600px) {
    .close-form-icon {
        margin-bottom: 16px;
        margin-right: 0;
    }
}

.form-group {
    margin-top: 40px
}

@media only screen and (max-width: 599px) {
    .form-group {
        margin-top: 24px
    }
}

.city-container {
    display: flex;
    justify-content: space-between;
}

.postal {
    flex: 1;
    margin-right: 16px;
}

.city {
    flex: 2
}

.label {
    margin-right: 21px
}

.button-form {
    background-color: #242424;
    border-radius: 34px;
    border: 1px solid #242424;
    color: white;
    padding: 12px 30px;
    width: 54px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
}
.button-form:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    background-color: #000000;
}

.disabled-button {
    opacity: 0.2;
    cursor: not-allowed;
}


@media only screen and (max-width: 599px) {
    .button-form {
        margin-top: 16px;
    }
}


/* INPUT */
input {
    font-family: "Poppins", "SansSerif", serif;
    font-size: 16px;
}

.input-container {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #000000;
    padding-bottom: 4px;
    outline: none;
    margin-bottom: 40px;
}

.input-error {
    border-color: #EB5757;
    color: #EB5757;
}

@media only screen and (max-width: 599px) {
    input {
        font-size: 14px;
    }
    .input-container {
        margin-bottom: 24px;
    }
}

.input-container:focus {
    border-bottom: 2px solid #000000;
    color: #242424;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #A2A2A2;
    opacity: 1; /* Firefox */
    font-family: "Poppins", "SansSerif", serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media only screen and (max-width: 599px) {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 12px;
        line-height: 18px;
    }
}

/* GENDER SELECTOR */
.gender-selector-container {
    display: flex;
    margin-bottom: 40px;
    font-size: 14px;
}

/* COUNTRY SELECTOR */
.country-selector-container {
    display: flex;
    margin-bottom: 40px;
    font-size: 16px;
    width: 100%
}

.country-selector-container select{
    width: 100%;
    font-size: 16px;
    padding-left: 2px;
    z-index: 1;
}

.country-selector-container select:required:invalid {
    color: #A2A2A2;
    font-size: 14px;
}

.country-selector-container select:focus {
    border-bottom: 2px solid #242424;
}

/* AGE SELECTOR */
.age-selector-container {
    margin-bottom: 40px;
}

.age-selector-container select {
    font-size: 16px;
}

.age-selector-container select:focus {
    border-bottom: 2px solid #242424;
}

@media only screen and (max-width: 599px) {
    .gender-selector-container {
        margin-bottom: 24px
    }
    .age-selector-container {
        margin-bottom: 24px
    }
    .age-selector-container select {
        font-size: 14px;
    }
    .country-selector-container select{
        font-size: 14px;
    }
    .country-selector-container {
        margin-bottom: 24px
    }
    .country-selector-container select:required:invalid {
        font-size: 12px;
    }
}

.disclaimer {
    font-size: 10px;
    text-align: left;
    margin-top: 40px;
}


.form-sent-container {
    z-index: 15;
    background-color: white;
    position: absolute;
    right: 0;
    height: 100%;
    width: calc(100% - 40px);
    color: #242424;
}

@media only screen and (max-width: 599px) {
    .form-sent-container {
        height: calc(100% - 40px);
        width: 100%;
        bottom: 0;
    }
}


@media only screen and (max-width: 1023px) and (min-width:600px) {
    .form-sent-container {
        height: calc(100% - 40px);
        width: 100%;
        bottom: 0;
    }
}

.form-sent-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.form-sent-tick {
    margin-bottom: 65px;
}

.form-sent-title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 12px;
    text-align: center;
    padding: 0 16px;
}

.form-sent-subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    padding: 0 16px;
}


.checkbox:first-child {
    margin-bottom: 12px;
}

.checkbox input[type="radio"] {
    opacity: 0;
    visibility: hidden;
}

.checkbox label {
    position: relative;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 26px;
}

.checkbox label::before,
.checkbox label::after {
    position: absolute;
    content: "";
    display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before{
    height: 16px;
    width: 16px;
    border: 1px solid;
    border-radius: 50%;
    left: 0;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
    /*height: 5px;*/
    /*width: 9px;*/
    /*border-left: 2px solid;*/
    /*border-bottom: 2px solid;*/

    /*transform: rotate(-45deg);*/

    /*left: 4px;*/
    /*top: 4px;*/
    width: 10px;
    height: 10px;
    border: 1px solid #000000;
    border-radius: 50%;
    left: 3px;
    top: 3px;
    background-color: #000000;
}

/*Hide the checkmark by default*/
.checkbox input[type="radio"] + label::after {
    content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="radio"]:checked + label::after {
    content: "";
}

.checkbox input[type="radio"]:checked + label  {
    font-weight: 600;
}


/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="radio"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
}


body, html, #root {
  height: 100vh;
  font-family: "Poppins", SansSerif, serif;
  color: white;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
}


.bg {
  /* The image used */
  background-image: url("background.jpg");
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*box-shadow: inset 0 70px 60px 0 rgba(0, 0, 0, 0.68), inset 0 -250px 240px 0 rgba(0, 0, 0, 0.68);*/
  backdrop-filter: blur(0.8);
}

/*@media only screen and (max-width: 599px) {*/
/*  .bg {*/
/*    box-shadow: inset 0 70px 60px 0 rgba(0, 0, 0, 0.68), inset 0 -70px 60px 0 rgba(0, 0, 0, 0.68);*/
/*  }*/
/*}*/

.container {
  position: absolute;
  width: calc(100% - 160px);
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

@media only screen and (max-width: 599px) {
  .container {
    width: calc(100% - 80px);
    padding: 0 40px;
  }
}

.content-container {
  margin-top: 0;
  width: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
}
.content-container:after {
  position: fixed;
  content: "";
  left: 0;
  top: 0;
  height: 25%;
  width: 100%;
  background: linear-gradient(black, transparent);
}


@media only screen and (max-width: 599px) {
  .content-container {
    margin-top: 0;
    width: calc(100% - 80px);
    position: absolute;
  }
}

.logo {
  top: 60px;
  position: fixed;
  width: 60px;
  height: 83px;
  z-index: 10;
}
.mobile-placeholder {
  height: 260px;
  mix-blend-mode: hard-light;
}

.opacity {
  /*opacity: 0.2;*/
  display: none !important
}

@media only screen and (max-width: 599px) {
  .logo {
    top: 40px;
  }
  .mobile-placeholder {
    height: 210px;
  }
}

@media only screen and (max-width: 1023px) and (min-width:600px) {
  .logo {
    top: 60px;
    position: fixed;
    width: 60px;
    height: 83px;
  }
  .mobile-placeholder {
    height: 230px;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 24px;
}

.text {
  font-size: 16px;
  line-height: 22px;
}

@media only screen and (min-width: 1440px) {
  .text {
    font-size: 18px;
    line-height: 24px;
  }
  .title {
    font-size: 40px;
    line-height: 46px;
  }
}

.button {
  width: 257px;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 34px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  margin-bottom: 60px;
}
.button:hover {
  background-color: white;
  color: #262525;
}


.button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 12px 30px;
}

.slider-container {
  position: relative;
}


@media only screen and (max-width: 1023px) and (min-width:600px) {
  .slider-container{
    margin-right: calc(-100% + 160px);
    width: unset;
  }
}

::-webkit-scrollbar {
  display: none;
}

.slider-container {
  margin-bottom: 100px;
}

.slider {
  display: flex;
  align-items: center;
}

.slick-slide {
  width: 85px;
  display: flex !important;
  justify-content: center;
}

.slider-arrow {
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}

.slider-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.ambassador-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65px;
  text-decoration: none;
  color: white;
  outline: none;
}

.ambassador-image {
  position: absolute;
  width: 45px;
  height: 45px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  filter: grayscale(100%);
}

.ambassador-image-placeholder {
  width: 45px;
  height: 45px;
  margin-bottom: 10px;
}

.ambassador-name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;
}